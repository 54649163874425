// import dateTimeHelper from "@/util/datetime_helper"
import model from "@/model"
import storageHelper from "@/util/local_storage_helper"
import firebaseAnalytics from "@/firebase/analytics";
import logger from "@/util/logger"

const LOG_TAG = 'app_bloc';
export default {
  logout() {
    logger.d(LOG_TAG, 'logout');
    if (storageHelper.isRememberMe()) {
      storageHelper.setAuth(
        storageHelper.getAuth().logout()
      )
    }
    window.store.commit('logout', true);
    window.store.commit('setAuth', model.authResponse());
    firebaseAnalytics.setEventLogout(process.env.VUE_APP_TARGET)
  },
  slackAppUpdates(version) {
    window.webapi.slackService.channelAppVersionUpdate(
      version,
      window.store.state.auth.getEmail(),
      () => {
        //
        logger.d(LOG_TAG, 'slackAppUpdates', 'success');
      }, (error) => {
        //
        logger.w(LOG_TAG, 'slackAppUpdates', error);
      });
  },
  slackAddonUpdates(options) {
    window.webapi.slackService.channelAppAddonUpdate(
      options,
      window.store.state.auth.getEmail(),
      () => {
        //
        logger.d(LOG_TAG, 'slackAddonUpdates', 'success');
      }, (error) => {
        //
        logger.w(LOG_TAG, 'slackAddonUpdates', error);
      });
  }
};
