import 'firebase/analytics';


export default {
  setUser(id) {
    window.firebaseAnalyticsRef.setUserId(id)
  },
  setEventLogin(from) {
    const param = {
      content_type: from
    }
    window.firebaseAnalyticsRef.logEvent('login', param);
  },
  setEventLogout(from) {
    const param = {
      content_type: from
    }
    window.firebaseAnalyticsRef.logEvent('logout', param);
  },
};
